export const FETCH_NEWS_REQUEST = "FETCH_NEWSS_REQUEST";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWSS_SUCCESS";
export const FETCH_NEWS_FAILURE = "FETCH_NEWSS_FAILURE";
export const ADD_NEWS_REQUEST = "ADD_NEWS_REQUEST";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_FAILURE = "ADD_NEWS_FAILURE";
export const DETAIL_NEWS_REQUEST = "DETAIL_NEWS_REQUEST";
export const DETAIL_NEWS_SUCCESS = "DETAIL_NEWS_SUCCESS";
export const DETAIL_NEWS_FAILURE = "DETAIL_NEWS_FAILURE";
export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAILURE = "DELETE_NEWS_FAILURE";
export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAILURE = "UPDATE_NEWS_FAILURE";

export const fetchNewsRequest = () => ({
  type: FETCH_NEWS_REQUEST,
});

export const fetchNewsSuccess = (news, totalPages, currentPage) => ({
  type: FETCH_NEWS_SUCCESS,
  payload: { news, totalPages, currentPage },
});

export const fetchNewsFailure = (error) => ({
  type: FETCH_NEWS_FAILURE,
  payload: error,
});
export const detailNewsRequest = () => ({
  type: DETAIL_NEWS_REQUEST,
});

export const detailNewsSuccess = (detail) => ({
  type: DETAIL_NEWS_SUCCESS,
  payload: { detail },
});

export const detailNewsFailure = (error) => ({
  type: DETAIL_NEWS_FAILURE,
  payload: error,
});

export const addNewsRequest = () => ({
  type: ADD_NEWS_REQUEST,
});

export const addNewsSuccess = (news) => ({
  type: ADD_NEWS_SUCCESS,
  payload: news,
});
export const addNewsFailure = (error) => ({
  type: ADD_NEWS_FAILURE,
  payload: error,
});
export const deleteNewsRequest = () => ({
  type: DELETE_NEWS_REQUEST,
});

export const deleteNewsSuccess = (news) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: news,
});
export const deleteNewsFailure = (error) => ({
  type: DELETE_NEWS_FAILURE,
  payload: error,
});
export const updateNewsRequest = () => ({
  type: UPDATE_NEWS_REQUEST,
});

export const updateNewsSuccess = (news) => ({
  type: UPDATE_NEWS_SUCCESS,
  payload: news,
});
export const updateNewsFailure = (error) => ({
  type: UPDATE_NEWS_FAILURE,
  payload: error,
});
