export const FETCH_ACTIVE_REQUEST = "FETCH_ACTIVE_REQUEST";
export const FETCH_ACTIVE_SUCCESS = "FETCH_ACTIVE_SUCCESS";
export const FETCH_ACTIVE_FAILURE = "FETCH_ACTIVE_FAILURE";

export const fetchActiveRequest = () => ({
  type: FETCH_ACTIVE_REQUEST,
});

export const fetchActiveSuccess = (active, totalPages, currentPage) => ({
  type: FETCH_ACTIVE_SUCCESS, // Correct action type
  payload: { active, totalPages, currentPage },
});

export const fetchActiveFailure = (error) => ({
  type: FETCH_ACTIVE_FAILURE,
  payload: error,
});
