import {
  FETCH_NEWS_REQUEST,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_FAILURE,
  ADD_NEWS_REQUEST,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_FAILURE,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAILURE,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAILURE,
  DETAIL_NEWS_REQUEST,
  DETAIL_NEWS_FAILURE,
  DETAIL_NEWS_SUCCESS,
} from "../actions/newsAction";

const initialState = {
  news: [],
  loading: false,
  error: null,
  totalPages: 0,
  page: 1,
  detail: null,
};

const newsReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload.news,
        loading: false,
        totalPages: action.payload.totalPages,
        page: action.payload.currentPage,
      };
    case FETCH_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DETAIL_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DETAIL_NEWS_SUCCESS:
      return {
        ...state,
        detail: action.payload.detail,
        loading: false,
      };
    case DETAIL_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: [...state.news, action.payload],
        error: null,
      };
    case ADD_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: state.news.filter((berita) => berita.id !== action.payload),
        error: null,
      };

    case DELETE_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: state.news.filter((berita) => berita.id !== action.payload),
        error: null,
      };

    case UPDATE_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default newsReducers;
