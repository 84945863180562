import {
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
} from "../actions/contactAction";

const initialState = {
  contact: [],
  loading: false,
  error: null,
  totalPages: 0,
  page: 1,
};

const contactReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload.contact,
        loading: false,
        totalPages: action.payload.totalPages,
        page: action.payload.currentPage,
      };
    case FETCH_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contact: [...state.contact, action.payload],
        error: null,
      };
    case ADD_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default contactReducers;
