import React, { useState } from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../App.css";

const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const closeNavbar = () => setExpanded(false);

  // Close navbar on route change
  React.useEffect(() => {
    closeNavbar();
  }, [location]);

  return (
    <Navbar
      bg="white"
      expand="lg"
      sticky="top"
      className="navbar-shadow"
      expanded={expanded}
    >
      <Container className="content-item-spacenbar">
        <Navbar.Brand as={Link} to="/" style={{ cursor: "pointer" }}>
          <Image
            src="/assets/logo.png"
            alt="Profile"
            className="logo-image mx-auto d-block"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" onClick={closeNavbar}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={closeNavbar}>
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/category" onClick={closeNavbar}>
              Products
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={closeNavbar}>
              Contact
            </Nav.Link>
            <Nav.Link as={Link} to="/news" onClick={closeNavbar}>
              News & Events
            </Nav.Link>
            <Nav.Link as={Link} to="/career" onClick={closeNavbar}>
              Careers
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
