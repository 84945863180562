// src/reducers/index.js
import { combineReducers } from "redux";
import authReducer from "./authReducers";
import newsReducers from "./newsReducers";
import jobReducers from "./jobReducers";
import contactReducers from "./contactReducers ";
import activeJobReducers from "./activeJobReducers";

const rootReducer = combineReducers({
  auth: authReducer,
  news: newsReducers,
  job: jobReducers,
  contact: contactReducers,
  active: activeJobReducers,
});

export default rootReducer;
