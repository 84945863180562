import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import NavigationBar from "./NavBar";

const MainLayoutLogged = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div className="flex h-screen">
      <NavigationBar />
      <div className="flex-grow">
        <div className="m-4">
          <div className="flex justify-end">
            <p className="text-xl mr-5">Hi! {localStorage.getItem("name")}</p>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mx-3"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>

          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayoutLogged;
