import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { FaBars, FaRegNewspaper } from "react-icons/fa";
import { RiContactsLine } from "react-icons/ri";
import { MdDashboard, MdOutlineWork } from "react-icons/md";
import "../App.css";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState("dashboard");
  const location = useLocation();

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  useEffect(() => {
    // Update activeMenu based on the current pathname
    switch (location.pathname) {
      case "/dashboard":
        setActiveMenu("dashboard");
        break;
      case "/news-admin":
        setActiveMenu("news-admin");
        break;
      case "/job-admin":
        setActiveMenu("job-admin");
        break;
      case "/contact-admin":
        setActiveMenu("contact-admin");
        break;
      default:
        setActiveMenu("Transactions");
    }
  }, [location.pathname]);

  return (
    <div
      className={`h-full ${
        isOpen ? "w-64" : "w-16"
      } transition-width duration-300 mr-4`}
    >
      <Sidebar collapsed={!isOpen} className="h-full">
        <div className="flex items-center p-4 mx-2 mb-3">
          <button onClick={() => setIsOpen(!isOpen)} className="p-2">
            <FaBars />
          </button>
          {isOpen && <h1 className="ml-4">BAS</h1>}
        </div>
        <Menu>
          <MenuItem
            component={<Link to="/dashboard" />}
            icon={<MdDashboard />}
            active={activeMenu === "dashboard"}
            onClick={() => handleMenuClick("dashboard")}
            className={activeMenu === "dashboard" ? "active" : ""}
          >
            Dashboard
          </MenuItem>

          <MenuItem
            component={<Link to="/news-admin" />}
            icon={<FaRegNewspaper />}
            active={activeMenu === "news-admin"}
            onClick={() => handleMenuClick("news-admin")}
            className={activeMenu === "news-admin" ? "active" : ""}
          >
            News
          </MenuItem>

          <MenuItem
            component={<Link to="/job-admin" />}
            icon={<MdOutlineWork />}
            active={activeMenu === "job-admin"}
            onClick={() => handleMenuClick("job-admin")}
            className={activeMenu === "job-admin" ? "active" : ""}
          >
            Job
          </MenuItem>
          <MenuItem
            component={<Link to="/contact-admin" />}
            icon={<RiContactsLine />}
            active={activeMenu === "cont"}
            onClick={() => handleMenuClick("cont")}
            className={activeMenu === "contact-admin" ? "active" : ""}
          >
            Contact
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default NavBar;
