export const FETCH_CONTACT_REQUEST = "FETCH_CONTACT_REQUEST";
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_FAILURE = "FETCH_CONTACT_FAILURE";
export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

export const fetchContactRequest = () => ({
  type: FETCH_CONTACT_REQUEST,
});

export const fetchContactSuccess = (contact, totalPages, currentPage) => ({
  type: FETCH_CONTACT_SUCCESS,
  payload: { contact, totalPages, currentPage },
});

export const fetchContactFailure = (error) => ({
  type: FETCH_CONTACT_FAILURE,
  payload: error,
});

export const addContactRequest = () => ({
  type: ADD_CONTACT_REQUEST,
});

export const addContactSuccess = (job) => ({
  type: ADD_CONTACT_SUCCESS,
  payload: job,
});
export const addContactFailure = (error) => ({
  type: ADD_CONTACT_FAILURE,
  payload: error,
});
