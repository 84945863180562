import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import MainLayoutLogged from "./layout/MainLayoutLogged";
import MainLayoutNotLogged from "./layout/MainLayoutNotLogged";
import routes from "./routes/routes";
import { TailSpin } from "react-loader-spinner";
import "./App.css";
import "animate.css";
import NotFound from "./components/NotFound";
import ReactGA from "react-ga4";

const App = () => {
  useEffect(() => {
    // Inisialisasi Google Analytics
    ReactGA.initialize("G-Q0NNZRZC65"); // Ganti dengan Measurement ID Anda
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Suspense
        fallback={
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="loading"
            />
          </div>
        }
      >
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.logged ? <MainLayoutLogged /> : <MainLayoutNotLogged />
              }
            >
              <Route
                path={route.path}
                element={<TrackPageView>{route.element}</TrackPageView>}
              />
            </Route>
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

const TrackPageView = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return children;
};

export default App;
