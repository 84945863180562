import {
  FETCH_ACTIVE_REQUEST,
  FETCH_ACTIVE_SUCCESS,
  FETCH_ACTIVE_FAILURE,
} from "../actions/activeJobAction";

const initialState = {
  active: [],
  loading: false,
  error: null,
  totalPages: 0,
  page: 1,
  detail: null,
};

const activeJobReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ACTIVE_SUCCESS:
      return {
        ...state,
        active: action.payload.active,
        loading: false,
        totalPages: action.payload.totalPages,
        page: action.payload.currentPage,
      };
    case FETCH_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default activeJobReducers;
