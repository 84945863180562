// src/layout/MainLayoutNotLogged.js
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import BackToTopButton from "../components/BackToTopButton";

const MainLayoutNotLogged = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && location.pathname === "/login") {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate, location]);

  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
      <BackToTopButton />
    </div>
  );
};

export default MainLayoutNotLogged;
