// routes/routes.js
import { lazy } from "react";

// Lazy-loaded components
const Login = lazy(() => import("../pages/Login"));
const Home = lazy(() => import("../pages/Home"));
const AboutFull = lazy(() => import("../pages/AboutFull"));
const Category = lazy(() => import("../pages/category/Category"));
const Coronary = lazy(() => import("../pages/category/Coronarys"));
const ProdXl = lazy(() => import("../pages/produtcs/ProdXl"));
const ProdRe = lazy(() => import("../pages/produtcs/ProdRe"));
const ProdEvro = lazy(() => import("../pages/produtcs/ProdEvro"));
const ProdEver = lazy(() => import("../pages/produtcs/ProdEver"));
const ProdForce = lazy(() => import("../pages/produtcs/ProdForce"));
const ProdSum = lazy(() => import("../pages/produtcs/ProdSum"));
const ProdPrimum = lazy(() => import("../pages/produtcs/ProdPrimum"));
const ProdPointer = lazy(() => import("../pages/produtcs/ProdPointer"));
const ProdLg = lazy(() => import("../pages/produtcs/ProdLg"));
const ProdAperto = lazy(() => import("../pages/produtcs/ProdAperto"));
const Peripheral = lazy(() => import("../pages/category/Peripheral"));
const Accessories = lazy(() => import("../pages/category/Accessories"));
const Contact = lazy(() => import("../pages/Contact"));
const Carrer = lazy(() => import("../pages/Carrer"));
const News = lazy(() => import("../pages/News"));
const NewsAdmin = lazy(() => import("../pages/admin/NewsAdmin"));
const JobAdmin = lazy(() => import("../pages/admin/JobAdmin"));
const DetailNews = lazy(() => import("../pages/admin/DetailNews"));
const DetailJob = lazy(() => import("../pages/admin/DetailJob"));
const NewsFull = lazy(() => import("../pages/NewsFull"));
const CareerFull = lazy(() => import("../pages/CareerFull"));
const ContactAdmin = lazy(() => import("../pages/admin/ContactAdmin"));
const Dashboard = lazy(() => import("../pages/admin/Dashboard"));

const routes = [
  {
    path: "/login",
    element: <Login />,
    logged: false,
  },
  {
    path: "/about",
    element: <AboutFull />,
    logged: false,
  },
  {
    path: "/category",
    element: <Category />,
    logged: false,
  },
  {
    path: "/contact",
    element: <Contact />,
    logged: false,
  },
  {
    path: "/career",
    element: <Carrer />,
    logged: false,
  },
  {
    path: "/career/:slug",
    element: <CareerFull />,
    logged: false,
  },
  {
    path: "/news",
    element: <News />,
    logged: false,
  },
  {
    path: "/news/:slug",
    element: <NewsFull />,
    logged: false,
  },
  {
    path: "/category/coronary",
    element: <Coronary />,
    logged: false,
  },
  {
    path: "/category/coronary/Xlimus-DES",
    element: <ProdXl />,
    logged: false,
  },
  {
    path: "/category/coronary/Evrocross",
    element: <ProdEvro />,
    logged: false,
  },
  {
    path: "/category/coronary/Restore-DEB",
    element: <ProdRe />,
    logged: false,
  },
  {
    path: "/category/coronary/Everest",
    element: <ProdEver />,
    logged: false,
  },
  {
    path: "/category/coronary/Force",
    element: <ProdForce />,
    logged: false,
  },
  {
    path: "/category/coronary/Summit",
    element: <ProdSum />,
    logged: false,
  },
  {
    path: "/category/peripheral",
    element: <Peripheral />,
    logged: false,
  },
  {
    path: "/category/peripheral/Legflow",
    element: <ProdLg />,
    logged: false,
  },
  {
    path: "/category/peripheral/Aperto",
    element: <ProdAperto />,
    logged: false,
  },
  {
    path: "/category/accessories",
    element: <Accessories />,
    logged: false,
  },
  {
    path: "/category/accessories/Primum",
    element: <ProdPrimum />,
    logged: false,
  },
  {
    path: "/category/accessories/Pointer",
    element: <ProdPointer />,
    logged: false,
  },
  {
    path: "/",
    element: <Home />,
    logged: false,
  },
  {
    path: "/home",
    element: <Home />,
    logged: true,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    logged: true,
  },
  {
    path: "/news-admin",
    element: <NewsAdmin />,
    logged: true,
  },
  {
    path: "/news-admin/:slug",
    element: <DetailNews />,
    logged: true,
  },
  {
    path: "/job-admin",
    element: <JobAdmin />,
    logged: true,
  },
  {
    path: "/job-admin/:slug",
    element: <DetailJob />,
    logged: true,
  },
  {
    path: "/contact-admin",
    element: <ContactAdmin />,
    logged: true,
  },
];

export default routes;
