import {
  FETCH_JOB_REQUEST,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_FAILURE,
  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILURE,
  DETAIL_JOB_REQUEST,
  DETAIL_JOB_FAILURE,
  DETAIL_JOB_SUCCESS,
} from "../actions/jobAction";

const initialState = {
  job: [],
  loading: false,
  error: null,
  totalPages: 0,
  page: 1,
  detail: null,
  active: [],
};

const jobReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_JOB_SUCCESS:
      return {
        ...state,
        job: action.payload.job,
        loading: false,
        totalPages: action.payload.totalPages,
        page: action.payload.currentPage,
      };
    case FETCH_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DETAIL_JOB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DETAIL_JOB_SUCCESS:
      return {
        ...state,
        detail: action.payload.detail,
        loading: false,
      };
    case DETAIL_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_JOB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        job: [...state.job, action.payload],
        error: null,
      };
    case ADD_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_JOB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        job: state.job.filter((pekerjaan) => pekerjaan.id !== action.payload),
        error: null,
      };

    case DELETE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_JOB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        job: state.job.filter((pekerjaan) => pekerjaan.id !== action.payload),
        error: null,
      };

    case UPDATE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default jobReducers;
