import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

import "../App.css"; // Pastikan untuk mengimpor CSS kustom

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        <Row>
          <Col md={4}>
            <h5>About</h5>
            <p>
              Established in 2015, PT Bintang Anugerah Sukses has significantly
              grown in the field of medical equipment distribution, specifically
              in Catheterization Laboratory (Cath Lab). We provide high-quality
              products and have experience and trust in the healthcare equipment
              industry.
            </p>
          </Col>
          <Col md={4}>
            <h5>Contact</h5>
            <ul className="list-unstyled contact-info">
              <li className="justify-text">Sales@pt-bas.com</li>
              <li className="mt-2  justify-text">Phone: +62 7306932</li>
              <li className="mt-2  justify-text">
                Address: Ruko Ubud Village Blok Kuta A No.25, Sudimara Timur,
                Ciledug, Kota Tangerang
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Follow Us</h5>
            <div className="d-flex">
              <a
                href="https://www.instagram.com/bintanganugerahsukses?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                className="text-white me-3"
              >
                <FaInstagram size={30} />
              </a>
              <a
                href="https://www.linkedin.com/company/pt-bintang-anugerah-sukses/"
                className="text-white me-3"
              >
                <FaLinkedin size={30} />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <p>
              &copy; {new Date().getFullYear()} PT. Bintang Anugerah Sukses. All
              rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
