export const FETCH_JOB_REQUEST = "FETCH_JOB_REQUEST";
export const FETCH_JOB_SUCCESS = "FETCH_JOB_SUCCESS";
export const FETCH_JOB_FAILURE = "FETCH_JOB_FAILURE";

export const ADD_JOB_REQUEST = "ADD_JOB_REQUEST";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_FAILURE = "ADD_JOB_FAILURE";
export const DETAIL_JOB_REQUEST = "DETAIL_JOB_REQUEST";
export const DETAIL_JOB_SUCCESS = "DETAIL_JOB_SUCCESS";
export const DETAIL_JOB_FAILURE = "DETAIL_JOB_FAILURE";
export const DELETE_JOB_REQUEST = "DELETE_JOB_REQUEST";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";
export const UPDATE_JOB_REQUEST = "UPDATE_JOB_REQUEST";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAILURE = "UPDATE_JOB_FAILURE";

export const fetchJobRequest = () => ({
  type: FETCH_JOB_REQUEST,
});

export const fetchJobSuccess = (job, totalPages, currentPage) => ({
  type: FETCH_JOB_SUCCESS,
  payload: { job, totalPages, currentPage },
});

export const fetchJobFailure = (error) => ({
  type: FETCH_JOB_FAILURE,
  payload: error,
});

export const detailJobRequest = () => ({
  type: DETAIL_JOB_REQUEST,
});

export const detailJobSuccess = (detail) => ({
  type: DETAIL_JOB_SUCCESS,
  payload: { detail },
});

export const detailJobFailure = (error) => ({
  type: DETAIL_JOB_FAILURE,
  payload: error,
});

export const addJobRequest = () => ({
  type: ADD_JOB_REQUEST,
});

export const addJobSuccess = (job) => ({
  type: ADD_JOB_SUCCESS,
  payload: job,
});
export const addJobFailure = (error) => ({
  type: ADD_JOB_FAILURE,
  payload: error,
});
export const deleteJobRequest = () => ({
  type: DELETE_JOB_REQUEST,
});

export const deleteJobSuccess = (job) => ({
  type: DELETE_JOB_SUCCESS,
  payload: job,
});
export const deleteJobFailure = (error) => ({
  type: DELETE_JOB_FAILURE,
  payload: error,
});
export const updateJobRequest = () => ({
  type: UPDATE_JOB_REQUEST,
});

export const updateJobSuccess = (job) => ({
  type: UPDATE_JOB_SUCCESS,
  payload: job,
});
export const updateJobFailure = (error) => ({
  type: UPDATE_JOB_FAILURE,
  payload: error,
});
