import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Memanggil halaman sebelumnya
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 text-white py-12 px-4 sm:px-6 lg:px-8 animate__animated animate__fadeIn">
      <div className="max-w-md w-full text-center">
        <h1 className="text-7xl font-extrabold animate__animated animate__zoomIn">
          404
        </h1>
        <p className="mt-4 text-2xl font-light animate__animated animate__fadeInUp animate__delay-1s">
          Oops! Page not found.
        </p>
        <p className="mt-2 text-lg animate__animated animate__fadeInUp animate__delay-2s">
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
        <button
          onClick={handleGoBack}
          className="mt-6 inline-block px-6 py-3 text-lg font-semibold text-white bg-blue-700 hover:bg-blue-800 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 animate__animated animate__fadeInUp animate__delay-3s"
        >
          <FaArrowLeft className="inline-block mr-2" />
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
